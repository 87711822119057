import React from "react";

const Hero = ({socials}) => {
  return (
    <section className="text-center py-16 pt-2 px-4">
      <div className="container max-w-screen-lg mx-auto">
        <img src="/images/logo1.png" alt="logo" className="h-36 mx-auto" />

        <h2 className="text-4xl font-extrabold text-white">
          A Tribute to Memes <br /> That Changed the Crypto World
        </h2>
        <p className="text-gray-400 mt-4">
          Explore the history of internet culture and memecoins in one place.
        </p>
        {/* <button
          id="buy"
          className="bg-orange-500 text-white px-6 py-2 rounded-lg  hover:bg-orange-600"
        >
          Buy Now
        </button> */}
        <p className="mt-6 text-orange-500">CA: {socials.ca}</p>
      </div>
    </section>
  );
};

export default Hero;

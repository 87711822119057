import React from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import MemeGallery from "./components/MemeGallery";
import Footer from "./components/Footer";

const socials = {
  twitter: "https://twitter.com/TheArtOfSolana",
  telegram: "https://t.me/TheArtOfSolana",
  ca: "3ew9Xfx4mwjkTo3UtaWtXdDv9bVqf6YM8DiHBqKvpump"
}

const App = () => {
  return (
    <div className="bg-[#1c1c1c] text-white font-sans">
      <Header socials={socials} />
      <Hero socials={socials} />
      <MemeGallery />
      <Footer socials={socials} />
    </div>
  );
};

export default App;
 
import React from "react";

const Header = ({socials}) => {
  return (
    <header className="flex justify-center items-center px-8 py-4 container max-w-screen-lg mx-auto">
      {/* <h1 className="text-2xl font-extrabold text-orange-500">MUSEUM OF MEMES</h1> */}
      <nav className="space-x-6 text-gray-300 text-center">
        {/* <a href="#buy" className="hover:text-white">Buy Now</a> */}
        <a href={socials.twitter} className="hover:text-white" target="_blank">Twitter</a>
        {/* <a href={socials.telegram} className="hover:text-white" target="_blank">Telegram</a> */}
      </nav>
    </header>
  );
};

export default Header;

import React, { useState, useMemo  } from "react";
import MemeCard from "./MemeCard";

const frames = [
  "/images/frame.webp",
  "/images/frame2.png",
];


const memes = [
  {
    src: "https://i.imgur.com/yle6M3T.mp4",
    caption:
      "Kid makes a coin called Quant, rugs for $30k however papered for $3M. He later got doxed & dog stolen",
    //author: "MemeLord69",
  },
  {
    src: "https://i.imgur.com/tJzYgTm.mp4",
    caption: "Dev pretends to have no hands, but quickly runs to his PC to sell all holdings. After selling, he goes back to act again",
    //author: "DegenInvestor",
  },
  {
    src: "https://i.imgur.com/T6UYeb6.mp4",
    caption: `Guy says "I am a stupid nigga" multiple times then eventually rugs`,
    //author: "CryptoChad",
  },
  {
    src: "https://i.imgur.com/2iaLyZ4.mp4",
    caption: "Two people doing a boxing match and eventually rugs",
    //author: "MoonLander",
  },
  // {
  //   src: "https://i.imgur.com/iQAzAtt.mp4",
  //   caption: "Kid gets his hooker mom to come on stream, show her titties when reaching a market cap goal",
  //   //author: "MoonLander",
  // },
  {
    src: "https://i.imgur.com/AFac88D.mp4",
    caption: "Sam Pepper attempts to confront a kid that has rugged (over $50K+) but gets a response with his shotgun and harmonica",
    //author: "MoonLander",
  },
  // {
  //   src: "https://i.imgur.com/NSL0DOA.mp4",
  //   caption: "One of the classics, squid game in the flesh. Only one winner",
  //   // author: "MoonLander",
  // },
  {
    src: "https://i.imgur.com/cQ7AaCK.mp4",
    caption: "Black guy with a gun decides to let one off as celebration for bonding",
    // author: "MoonLander",
  },
  
  {
    src: "https://i.imgur.com/Ot0cBuD.mp4",
    caption: "Black guy flexing after successfully rugging for 8 SOL",
    // author: "MoonLander",
  },

  {
    src: "https://i.imgur.com/vowLj7U.mp4",
    caption: "Black guy celebrating with his gun"
  },

  {
    src: "https://i.imgur.com/jTGBnrU.mp4",
    caption: "Spiderman getting smacked on coke"
  },

  {
    src: "https://i.imgur.com/eObe43O.mp4",
    caption: "No words"
  }
  
];

const MemeGallery = () => {
  const randomFrame = useMemo(() => {
    return frames[Math.floor(Math.random() * frames.length)];
  }, []);
  const [selectedMeme, setSelectedMeme] = useState(null);

  const closeModal = () => setSelectedMeme(null);

  return (
    <section className="py-12 px-4 bg-black">
      <div className="container max-w-screen-xl mx-auto">
        <h3 className="text-4xl font-extrabold text-center text-white mb-8">
          MEME GALLERY
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {memes.map((meme, index) => (
            <div
              key={index}
              className="relative group overflow-hidden rounded-lg cursor-pointer"
              onClick={() => setSelectedMeme(meme)}
            >
              {/* Content */}
              <div className="relative group overflow-hidden rounded-lg cursor-pointer hover:scale-105 transition-transform duration-300">
                {/* Content */}
                {meme.type === "image" ? (
                  <img
                    src={meme.src}
                    alt={meme.caption}
                    className="w-full h-auto object-cover rounded-lg "
                  />
                ) : (
                  <video
                    src={meme.src}
                    muted
                    loop
                    className="w-full h-auto object-cover rounded-lg p-8"
                  />
                )}

                {/* Frame Overlay */}
                <img
                  src={randomFrame}
                  alt="Frame"
                  className="absolute inset-0 w-full h-full z-10 pointer-events-none "
                />
              </div>

              {/* Frame Overlay */}
              {/* <div
                className="absolute inset-0 bg-cover bg-center pointer-events-none"
                style={{
                  backgroundImage: `url('/images/frame.webp')`,
                  zIndex: 10, // Ensure frame is above content
                }}
              ></div> */}

              {/* Caption */}
              <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent text-white p-4 z-20">
                <p className="text-sm" >{meme.caption}</p>
                {/* <p className="text-xs text-gray-400 mt-1">- {meme.author}</p> */}
              </div>
            </div>
          ))}
        </div>

        <p className="text-center text-gray-400 mt-6">
          Please send more memes or funny videos to our Twitter page so we can keep adding them to the collection
        </p>
      </div>

      {/* Modal for Focused View */}
      {selectedMeme && (
        <div
          className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div
            className="relative bg-black rounded-lg p-4 max-w-3xl w-full"
            onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking inside
          >
            {/* Content */}
            {selectedMeme.type === "image" ? (
              <img
                src={selectedMeme.src}
                alt={selectedMeme.caption}
                className="w-full h-auto object-cover rounded-lg"
              />
            ) : (
              <video
                src={selectedMeme.src}
                controls
                autoPlay
                className="w-full h-auto object-cover rounded-lg"
              />
            )}

            {/* Frame Overlay */}
            {/* <div
                className="absolute inset-0 bg-cover bg-center pointer-events-none"
                style={{
                  backgroundImage: `url('/images/frame.webp')`,
                  zIndex: 10, // Ensure frame is above content
                }}
              ></div> */}

            {/* Caption */}
            <div className="mt-4 text-center">
              <p className="text-lg text-white">{selectedMeme.caption}</p>
              {/*<p className="text-sm text-gray-400 mt-2">
                - {selectedMeme.author}
              </p> */}
            </div>
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 bg-orange-500 text-white p-2 rounded-full hover:bg-orange-600"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default MemeGallery;

import React from "react";

const Footer = ({socials}) => {
  return (
    <footer className="bg-black py-12 px-4">
      <div className="container max-w-screen-lg mx-auto text-center">
        <h2 className="text-xl font-bold text-orange-500 mb-4">Get Involved</h2>
        <div className="flex justify-center space-x-4">
          <a
            id="twitter"
            className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600"
            href={socials.twitter}
            target="_blank"
          >
            Twitter
          </a>
          {/* <a
            id="telegram"
            className="bg-blue-700 text-white px-6 py-2 rounded-lg hover:bg-blue-800"
            href={socials.telegram}
            target="_blank"
          >
            Telegram
          </a> */}
          {/* <button
            id="buy"
            className="bg-orange-500 text-white px-6 py-2 rounded-lg hover:bg-orange-600"
          >
            Buy Now
          </button> */}
        </div>
        <p className="text-gray-400 mt-6 text-sm">
          Memes are more than just jokes – they're culture. Join the movement.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
